import React from 'react'
import SearchBar from '../../components/SearchBar/searchBar'
import * as Styled from './home.styled.js'


export default function Home() {
  return (
<Styled.Container>  
    <SearchBar></SearchBar>  
</Styled.Container>
  )
}
